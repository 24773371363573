import "../scss/app.scss";
import Vue from "vue";
import Swiper from "swiper/bundle";
import "swiper/swiper-bundle.css";
import VueScrollClass from "vue-scroll-class";
import "splitting/dist/splitting.css";
import "splitting/dist/splitting-cells.css";
import Splitting from "splitting";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const app = new Vue({
  el: "#app",
  data: {
    isLoading: true,
    isToggleMenu: false,
    isSwipeChange: false,
    topNews: "all",
  },
  mounted() {
    setTimeout(this.opening, 400);
    Splitting();
    this.InView();
  },
  methods: {
    opening() {
      const tl = gsap.timeline({
        repeat: 0,
        defaults: {
          delay: 0.1,
          duration: 0.3,
          ease: "circ.out",
        },
      });
      tl.from(".logoAnimeBg", {
        scaleX: 0,
      })
        .to(".logoAnime", {
          opacity: 1,
          duration: 0.3,
          ease: "bounce.in",
        })
        .to(".logoAnimeBg", {
          scaleX: 1,
          transformOrigin: "left top",
          ease: "power1.inOut",
        })
        .to(".logoAnimeBg", {
          delay: 0.6,
          scaleX: 0,
          duration: 0.6,
          ease: "power1.inOut",
          transformOrigin: "right top",
        })
        .to(".logoAnime", {
          opacity: 0,
          delay: 0.6,
          duration: 0.6,
          ease: "bounce.out",
        })
        .to(".logoAnime", {
          delay: 0.6,
          onComplete: this.openingFinish,
        });
      // this.loading = false;
    },
    heroSwiper() {
      const swiper = new Swiper(".swiper-container", {
        loop: true,
        speed: 1500,
        effect: "fade",
        // fadeEffect: {
        //   crossFade: true,
        // },
        autoplay: {
          delay: 3000,
          waitForTransition: false,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          type: "fraction",
          renderFraction: function(currentClass, totalClass) {
            return `<div class="barPagination"><div class="${currentClass}"></div><div class="bar"></div><div class="${totalClass}"></div></div>`;
          },
        },
        on: {
          autoplay: function() {
            // console.log(swiper);
          },
        },
      });
    },
    openingFinish() {
      this.heroSwiper();
      this.isLoading = false;
    },
    InView() {
      const trigger = document.querySelectorAll("[data-trigger]");
      trigger.forEach(function(userItem) {
        ScrollTrigger.create({
          trigger: userItem,
          start: "top bottom-=50px",
          end: "bottom top+=50px",
          toggleClass: { targets: userItem, className: "InView" },
        });
      });
    },
  },
  directives: {
    "scroll-class": VueScrollClass,
  },
});
